import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CustomerObjectContactDto, CustomerObjectWithContactsDto } from "../../../models/customerObject/customer-object-with-contacts.dto";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { CustomerObjectsService } from "../../../../categories/customer-objects/customer-objects.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { NotificationType } from "../../../../core/services/notification-type";
import { GridComponent } from "@progress/kendo-angular-grid";
import { AddressSuggestion } from "../../../models/dadata-address/address-suggestion";

@Component({
	selector: 'request-create-customer-object',
	templateUrl: './request-create-customer-object.component.html',
	styleUrls: [
		'./request-create-customer-object.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class RequestCreateCustomerObjectComponent implements AfterContentInit {
    
	@ViewChild("contactsGrid") contactsGrid: GridComponent;

	loading: boolean = false;

	clientDefaultItem: KeyValueObject = new KeyValueObject(0, 'Не выбрано');
	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

    @Input() selectedCustomerObject: CustomerObjectWithContactsDto;
    @Input() customerObjects: KeyValueObject[] = [];
    @Input() contragentId: number;
    @Input() isEditing: boolean = false;

    @Output() onCancelEvent: EventEmitter<any> = new EventEmitter();
    @Output() onContinueEvent: EventEmitter<CustomerObjectWithContactsDto> = new EventEmitter<CustomerObjectWithContactsDto>();

	entity: CustomerObjectWithContactsDto;

    constructor(
        public customerObjectsService: CustomerObjectsService,
        public notificationService: NotificationService
    ) { }

	ngAfterContentInit(): void {
        this.entity = {
            customerObjectId: this.selectedCustomerObject.customerObjectId,
            name: this.selectedCustomerObject.name,
            address: this.selectedCustomerObject.address,
            contragentId: this.selectedCustomerObject.contragentId,
            contacts: this.selectedCustomerObject.contacts.map(x => {
                return {
                    contactId: x.contactId,
                    fullName: x.fullName,
                    phoneNumber: x.phoneNumber,
                    isContragentContact: x.isContragentContact
                }
            })
        } as CustomerObjectWithContactsDto;
	}

	save() {
		this.loading = true;
        this.entity.contragentId = this.contragentId;
		if (this.isEditing) {
			this.customerObjectsService.updateCustomerObjectWithContacts(this.entity).subscribe((resp) => {
				this.refreshAndContinue(this.entity.customerObjectId, resp.isSuccessful, resp.errorDescription);
			}, () => this.loading = false);
		}
		else {
			this.customerObjectsService.createCustomerObjectWithContacts(this.entity).subscribe((resp) => {
				this.entity.customerObjectId = resp.data;
                this.refreshAndContinue(resp.data, resp.isSuccessful, resp.errorDescription);
			}, () => this.loading = false);
		}
	}

	refreshAndContinue(id: number, isSuccessful: boolean, errorMessage: string = null) {
		if (isSuccessful) {
			this.customerObjectsService.getCustomerObjectWithContacts(id).subscribe((getResp) => {
				this.loading = false;
				this.entity = getResp;
				this.onContinueEvent.emit(this.entity);
			}, () => this.loading = false);
		}
		else {
			this.notificationService.error({
				title: 'Ошибка',
				message: errorMessage,
				notificationType: NotificationType.SweetAlert
			});
			this.loading = false;
		}
	}

    cancel() {
        this.onCancelEvent.emit();
    }

    customerObjectChange(id: number) {
        this.customerObjectsService.getCustomerObjectWithContacts(id).subscribe((resp) => {
            this.entity = resp;
        });
    }

	onAddressChange(value: AddressSuggestion) {
		this.entity.address = value.address;
	}

    createNewContact(): CustomerObjectContactDto {
        var contact = new CustomerObjectContactDto();
        return contact;
    }

    addNewContact() {
		this.contactsGrid.addRow(this.createNewContact());
	}
    
    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    get gridHeight(): number {
        return this.entity.contacts.length * 50 + 100;
    }

	get canCreate(): boolean {
		return this.entity != null &&
			this.entity.name != null && this.entity.name.trim() != '' &&
			this.entity.address != null && this.entity.address.trim() != '';
	}
}
