import { DialogService } from "@progress/kendo-angular-dialog";
import { ContragentsService } from "../../../../categories/contragents/contragents.service";
import { ContragentWithContactDto } from "../../../../shared/models/contragent/contragent-with-contact-dto";
import { RequestCreateContragentComponent } from "../../../../shared/components/new-request/create-contragent/request-create-contragent.component";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { Subscription } from "rxjs";

@Component({
	selector: 'new-request-client-info',
	templateUrl: './new-request-client-info.component.html',
	styleUrls: ['./new-request-client-info.component.scss']
})
export class NewRequestClientInfoComponent {

    @Input() selectedContragent: ContragentWithContactDto;

    @Output() saveContragent: EventEmitter<ContragentWithContactDto> = new EventEmitter<ContragentWithContactDto>();

	contragentSearchPauseTime = 500;
	contragentsSearchDelay: NodeJS.Timer;
	contragentsSearchSubsription: Subscription;

	defaultContragent: KeyValueObject = new KeyValueObject(null, 'Не выбрано');
	contragents: KeyValueObject[] = [];
	selectedContragentId: number;
	
    constructor(
        public contragentsService: ContragentsService,
        public dialogService: DialogService
    ) { }

    clientChange(id: number): void {
		if (id == null || id == 0) {
			this.selectedContragent = null;
			this.saveContragent.emit(this.selectedContragent);
		}
		else {
			this.contragentsService.getContragentWithContact(id).subscribe(resp => {
				this.selectedContragent = resp;
				this.saveContragent.emit(this.selectedContragent);
			});
		}
	}

	clientFilterChange(value: string): void {
		if (!value || value.trim() == '' || value.trim().length < 3) {
			this.contragents = this.selectedContragent != null
				? this.contragents.filter(x => x.id == this.selectedContragentId)
				: [];
		}
		else {
			this.searchContragents(value);
		}		
	}

	searchContragents(value: string) {
		if (this.contragentsSearchDelay) {
			clearTimeout(this.contragentsSearchDelay);
		}

		this.contragentsSearchDelay = setTimeout(() => {
			if (this.contragentsSearchSubsription) {
				this.contragentsSearchSubsription.unsubscribe();
			}

			this.contragentsSearchSubsription = this.contragentsService.searchByName(value)
            .subscribe((response) => {
                this.contragents = response;
			});
		}, this.contragentSearchPauseTime);
	}

	openCreateContragentDialog() {
		const dialogRef = this.dialogService.open({ content: RequestCreateContragentComponent, width: '50%', height: '95%' });
		const createContragentForm = <RequestCreateContragentComponent>dialogRef.content.instance;
		
		createContragentForm.isEditing = this.selectedContragent != null;
		createContragentForm.contragentEntity = this.selectedContragent;
		createContragentForm.onCancelEvent.subscribe(() => dialogRef.close());
		createContragentForm.onContinueEvent.subscribe((contragent) => {
			this.selectedContragent = contragent;
            this.saveContragent.emit(this.selectedContragent);
			this.selectedContragentId = contragent.id;

			let contragentDisplayName = contragent.name == null || contragent.name.trim() == ''
				? contragent.contactFullName == null || contragent.contactFullName.trim() == ''
					? contragent.contactPhone
					: contragent.contactFullName
				: contragent.name;
			this.contragents = [new KeyValueObject(contragent.id, contragentDisplayName)];

			this.clientFilterChange(null);
			dialogRef.close();
		});
	}
}
