import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerObject } from '../../shared/models/customerObject/CustomerObject';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ServiceCenterLookupModel } from '../../shared/models/service-center/service-center-lookup';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { TidFullLookupModel } from '../../shared/models/tid/tid-full';
import { CustomerObjectWithContactsDto } from '../../shared/models/customerObject/customer-object-with-contacts.dto';
import { ActionResult } from '../../shared/models/core/ActionResult';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';

@Injectable()
export class CustomerObjectsService extends DataService<CustomerObject, CustomerObject> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'customer-objects';
	}

	public getServiceCenters(customerObjectId: number): Observable<ServiceCenterLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${customerObjectId}/service-centers`);
	}

	public getTids(customerObjectId: number, agreementId: number = null): Observable<TidFullLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${customerObjectId}/tids?agreementId=${agreementId}`);
	}

	public getEngineers(customerObjectId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${customerObjectId}/engineers`);
	}

	public getCustomerObjectsByContragent(contragentId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${contragentId}/by-contragent`);
	}

	public getCustomerObjectWithContacts(cutomerObjectId: number): Observable<CustomerObjectWithContactsDto> {
		return this.getQuery<CustomerObjectWithContactsDto>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-with-contacts/${cutomerObjectId}`);
	}

	public createCustomerObjectWithContacts(entity: CustomerObjectWithContactsDto): Observable<ActionDataResult<number>> {
		return this.postQuery<number>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-with-contacts`, entity);
	}

	public updateCustomerObjectWithContacts(entity: CustomerObjectWithContactsDto): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/update-with-contacts`, entity);
	}
}
