import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { LookupService } from "../../../../shared/services/lookup.service";
import { forkJoin } from "rxjs";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { NewRequestService } from "../../../../shared/models/request/new-request/new-request-service";
import { CategoryServicesService } from "../../../../shared/services/category-services.service";
import { ServiceItem } from "../../../../shared/models/services/service-item";
import { GridComponent } from "@progress/kendo-angular-grid";

@Component({
	selector: 'new-request-services-and-devices',
	templateUrl: './new-request-services-and-devices.component.html',
	styleUrls: ['./new-request-services-and-devices.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class NewRequestServicesAndDevicesComponent implements OnInit {

    @ViewChild("servicesGrid") servicesGrid: GridComponent;

    @Input() disabled: boolean;
    
    @Output() saveRequestServices: EventEmitter<NewRequestService[]> = new EventEmitter();

    private defaultServiceTypeId = 2;
    private defailtServiceCategoryId = 4;

    fullServices: ServiceItem[] = [];
    services: KeyValueObject[] = [];
    serviceTypes: KeyValueObject[] = [];
    serviceCategories: KeyValueObject[] = [];

    requestServices: NewRequestService[] = [];

    constructor(
        public categoryServicesService: CategoryServicesService,
        public lookupService: LookupService
    ) { }

    ngOnInit(): void {
        let $fullServices = this.categoryServicesService.list();
        let $serviceTypes = this.lookupService.getData('service-types', null);
        let $serviceCategories = this.lookupService.getData('service-categories', null);

        forkJoin($fullServices, $serviceTypes, $serviceCategories)
            .subscribe(([fullServices, serviceTypes, serviceCategories]) => {
                this.services = fullServices.data.map(x => new KeyValueObject(x.id, x.name));
                this.fullServices = fullServices.data;
                this.serviceTypes = serviceTypes;
                this.serviceCategories = serviceCategories;
            });
    }

    changeRequestTypeAndCategory(requestTypeId: number, requestCategoryId: number) {
        if (requestTypeId == null || requestTypeId == 0 || 
            requestCategoryId == null || requestCategoryId == 0) {
            this.requestServices = [];
            return;
        }

        this.requestServices = this.fullServices
            .filter(x => 
                x.requestTypeIds.some(rt => rt == requestTypeId) &&
                x.requestCategoryIds.some(rc => rc == requestCategoryId))
            .map(x => {
                return {
                    serviceId: x.id,
                    serviceName: x.name,
                    serviceTypeId: this.defaultServiceTypeId,
                    serviceCategoryId: this.defailtServiceCategoryId
                } as NewRequestService;
            });

        this.saveRequestServices.emit(this.requestServices);
    }

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    createNewService(): NewRequestService {
        let service = { 
            serviceTypeId: this.defaultServiceTypeId,
            serviceCategoryId: this.defailtServiceCategoryId
        } as NewRequestService;

        return service;
    }

    refreshRequestServices() {
        this.saveRequestServices.emit(this.requestServices);
    }

    addNewService() {
		this.servicesGrid.addRow(this.createNewService());
	}
    
    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    get gridHeight(): number {
        return this.requestServices.length * 50 + 150;
    }
}