export class ContragentWithContactDto {
    public id: number;
	public name: string;
	public legalName: string;
	public inn: string;
	public accountingInfo: string;
	public contactFullName: string;
	public contactFirstName: string;
	public contactMiddleName: string;
	public contactLastName: string;
	public contactAddress: string;
	public contactPhone: string;
	public contactEmail: string;
}